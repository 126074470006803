<template>
  <BaseLayout title="会员" height="0" bg="linear-gradient(90deg, #EA0, #000 3%, #000 97%, #EA0)" noback>

    <div class="head" :style="{background:bg}">
      <div class="mine">
        <div class="vipname">{{vipname}} </div>
        <div class="expire">{{expire}}</div>
      </div>
    </div>

    <van-swipe class="swipe" :autoplay="8000" :loop="true" :width="300" :show-indicators="false" @change="onChange">
      <van-swipe-item v-for="(item,index) in state.vip" :key="index">
        <div class="card" :style="{color:item.color}">
          <div class="name">
            <van-icon name="vip-card" size="40" :color="item.color" :style="{marginRight:'10px'}"/>
            <p>{{item.name}}</p>
          </div>
          <div class="price" v-text="item.intro ? item.intro : item.price + ' U / 年'"></div>
        </div>
      </van-swipe-item>
    </van-swipe>

    <div class="content">
      <div class="title" :style="{color:state.current.color}">⋙&nbsp;&nbsp;{{state.current.name}}权益&nbsp;&nbsp;⋘</div>
      <div class="block">
        <van-row class="item" v-for="(item, index) in state.feature" :key="index" align="center" justify="start">
          <van-col span="4" class="icon"><van-icon :name="item.icon" size="45" :color="state.current.color"/></van-col>
          <van-col span="14" class="name">{{item.name}}</van-col>
          <van-col span="6"  class="value">{{item.value}}</van-col>
          <van-col span="24" class="desc">{{item.desc}}</van-col>
        </van-row>
      </div>
      <br/>
    </div>

    <van-sticky position="bottom" :offset-bottom="50">
      <van-button class="buy" block color="#EA0" size="large" @click="onBuy">点我升级享受更多权益</van-button>
    </van-sticky>

    <van-popup v-model:show="state.show" round closeable position="bottom">
      <van-radio-group class="choose" v-model="state.choose">
        <div v-for="(item,index) in state.vip" :key="index" >
          <van-radio class="option" :name="index" :disabled="level>=parseInt(index.replace('v', ''))">{{item.name}}（{{item.price}} USDT / 年）</van-radio>
        </div>
      </van-radio-group>
      <van-submit-bar :price="fee*100" currency="$" label="实付：" :suffix-label="' 余额：$' + balance" button-text="升级 / 续费" tip="实付金额 = 所选会员年费 - 已付会员剩余年费" tip-icon="info-o" @submit="onPay" />
    </van-popup>

  </BaseLayout>
</template>

<style scoped>
.head {
  width: 330px;
  height: 200px;
  margin: 10px auto 0 auto;
  border-radius: 10px;
  border:dotted 1px #EA0;
  overflow: hidden;
}
.mine {
  display: flex;
  flex-flow: column nowrap;
  padding: 100px 0 0 25px;
  line-height: 30px;
}
.vipname {
  color: #C90;
  font-size: 24px;
}
.expire {
  padding: 35px 15px 0 0;
  color: #999;
  font-size: 12px;
  text-align: right;
}


.swipe {
  width: 330px;
  margin:0 auto;
}
.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin: 20px 10px 20px 0;
  background: #222;
  border-radius: 5px;
  border: solid 1px #970;
}
.card .name {
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 26px;
  font-weight: 400;
}
.card .price {
  padding-right:10px;
  font-size: 16px;
}

.content {
  width: 330px;
  margin: 0 auto;
  background: #222;
  border-radius: 10px;
  color: #EEE;
}
.title {
  padding: 15px;
  font-size: 16px;
  text-align: center;
}
.block {
  margin: 5px;
  padding: 10px;
  background: #000;
  border-radius: 10px;
  border: solid 1px #970;
}
.block .icon {
  position: relative;
  top: 10px;
  left: -3px;
}
.block .name {
  font-size: 18px;
}
.block .value {
  font-size: 14px;
  text-align: right;
}
.block .desc {
  margin-bottom: 10px;
  padding-left: 50px;
  color: #999;
  font-size: 12px;
  line-height: 20px;
}

.buy {
  width: 320px;
  margin: 0 auto;
}
.choose {
  height: 220px;
  margin: 20px;
}
.option {
  padding: 8px;
}
</style>

<script>
import {reactive} from 'vue'
import {mapState, mapGetters} from 'vuex'
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  components: {BaseLayout},

  setup(){
    
    let current = {};
    let feature = {};

    let vip = {
      "v1": {
        name: "银卡",
        color: "#FFF",
        price: "0",
        intro: "限时免费",
        quota: [1, 2, "不", "不"],
      },
      "v2": {
        name: "金卡",
        color: "#FF0",
        price: "100",
        quota: [3, 10, "不", "不"],
      },
      "v3": {
        name: "翡翠卡",
        color: "#0F0",
        price: "500",
        quota: [10, 30, "", "不"],
      },
      "v4": {
        name: "钻石卡",
        color: "#0FF",
        price: "2000",
        quota: [30, 100, "", ""],
      },
    };

    let fun = [{
      icon: "font-o",
      name: "策略模板",
      value: " 个",
      desc: "可以根据不同的币种制定不同的量化策略",
    },{
      icon: "cash-back-record",
      name: "手动建仓",
      value: " 个",
      desc: "手动建仓买进选择好的币种，机器人根据设定的量化策略实时跟踪、自动交易；手动建仓和自动建仓共享同时在线任务额度",
    },{
      icon: "after-sale",
      name: "自动建仓",
      value: "支持",
      desc: "选择系统内置的建仓方案和设定好的量化策略，机器人自动建仓、自动交易",
    },{
      icon: "expand-o",
      name: "自定义自动建仓方案",
      value: "支持",
      desc: "设置不同指标组合，自定义建仓方案，机器人根据该方案和设定的量化策略自动建仓、自动交易",
    },];

    let show = false;
    let choose = "v4";

    const state = reactive({current, feature, vip, fun, show, choose});
    return {state};
  },

  created() {
    this.state.current = this.state.vip["v" + 1];
    this.state.feature = this.state.fun;
  },

  mounted(){
    this.onChange(0);
  },

  computed: {
    bg(){
      return "url(" + require('../assets/vip.png') + ") no-repeat top center";
    },
    
    vipname(){
      if (!this.authorized)
        return "尚未登录";
      if (!this.state.vip["v" + this.level])
        return "精英会员";
      return this.state.vip["v" + this.level]["name"] + "会员";
    },
    expire(){
      if (!this.authorized)
        return "有效期登录后可见";
      if (this.profile.deadline)
        return "有效期至 " + this.profile.deadline;
      return "";
    },

    fee(){
      let total = this.state.vip[this.state.choose]["price"];

      let d = new Date();
      let today = d.getFullYear() + '-' + ((d.getMonth() + 1)/100).toString().substr(2, 2) + '-' + (d.getDate()/100).toString().substr(2, 2);
      if (this.level == 0 || !this.profile.deadline || this.profile.deadline <= today)
        return total;

      let period = Math.ceil((new Date(this.profile.deadline).getTime() - new Date().getTime()) / (24*3600*1000));
      let remain = this.state.vip["v" + this.level].price * period / 365;
      return parseFloat(total - remain);
    },

    ...mapState(["profile"]),
    ...mapGetters(["authorized", "uid", "nickname", "balance", "level"]),
  },

  methods: {
    onChange(index) {
      this.state.current = this.state.vip["v" + (index + 1)];
      let arr = [];
      for (let i in this.state.fun){
        let str = this.state.current.quota[i];
        let obj = {...{}, ...this.state.fun[i]};
        obj.value = str + obj.value;
        arr.push(obj);
      }
      this.state.feature = arr;
    },

    onBuy(){
      if (!this.authorized){
        this.$toast("请先登录");
        this.$router.push("/login");
        return;
      }
      this.state.show = true;
    },
    onPay(){
      if (this.fee < 0)
        return this.$toast("请先选择要升级的会员类型");

      if (this.balance < this.fee){
        return this.$dialog.confirm({
          title: "余额不足",
          message: "现在就去充值？",
          theme: "round-button",
        }).then(() => {
            this.$router.push("/my/deposit");
        });
      }
      
      this.$http.post("/trade/buy?vip=" + this.state.choose).then((data) => {
        this.state.show = false;
        this.$init();
        this.$dialog.alert({message: "恭喜您，会员升级成功！", theme: "round-button"});
      });
    },

  },
}
</script>
